import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import GlobalLayout from "components/page/GlobalLayout";
import WebinarTemplate from "components/webinar/templates/WebinarTemplate";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function LvcCommercialRealEstate() {
  const renderContent = data => {
    const headerSection = {
      preTitle: "LIVE WEBINAR",
      date: "Wednesday, January 17th at 9:30am PST",
      title: "Smarter Video Security for Commercial Real Estate ",
      formId: "57b79d6c-8e9e-4fb3-94c0-b370f7204225",
      formTitle: "Watch the Webinar",
      image: data.headerImage,
    };

    const aboutSection = {
      title: "About the Webinar",
      image: data.aboutImage,
      about: [
        "When it comes to managing commercial real estate, video security is an invaluable piece of your overall security and safety strategy. With a unified platform that’s easy to deploy, LVC and Rhombus are here to help you protect resident safety with plug-and-play smart cameras, seamless access control, IoT sensors, and an integration-friendly cloud system.",
      ],
      learnTitle: "What You'll Learn in 45 Minutes",
      learnLines: [
        "Gain 24/7 remote access and visibility across all buildings and locations",
        "Quickly find footage and investigate incidents with smart video filters",
        "Accelerate emergency response with real-time alerts and easy video clip sharing",
        "Integrate Rhombus with best-in-class solutions for access control, environmental sensors, and more",
        "Q&A session with industry experts",
      ],
      speakers: [
        {
          name: "Phil Wendt",
          title: "Channel Account Manager",
          company: "Rhombus",
          image: data.Phil,
        },
        {
          name: "Kelsey Kerling",
          title: "Director of Technology Sales",
          company: "LVC",
          image: data.Kelsey,
        },
      ],
      reverse: true,
      logos: [data.logo1, data.logo2],
    };
    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>
            Webinar: Smarter Video Security for Commercial Real Estate
          </title>
          <meta
            name="description"
            content="With a unified platform that’s easy to deploy, LVC and Rhombus are here to help you protect resident safety."
          />
          <meta name="robots" content="noindex, nofollow" />
          <meta
            property="og:image"
            content="https://rhombus.com/img/MetaTag-LVCWebinar-min.png"
          />
        </Helmet>
        <WebinarTemplate
          headerSection={headerSection}
          aboutSection={aboutSection}
          imageCenter
        >
          <TrustedPartnerSection button={false} color="var(--nuetral-100)" />
        </WebinarTemplate>
      </GlobalLayout>
    );
  };
  const QUERY = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/retail-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      aboutImage: file(
        relativePath: { eq: "components/webinar/img/whole-foods-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      Phil: file(
        relativePath: { eq: "components/partner-case-study/img/phil-w.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      Kelsey: file(
        relativePath: { eq: "components/common/img/headshots/kelsey.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      logo1: file(
        relativePath: { eq: "components/live-webinar/img/rhombus-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/lvc-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={QUERY} render={renderContent} />;
}
