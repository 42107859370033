import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import React from "react";

import WebinarHero2 from "../../common/hero/WebinarHero2";
import WebinarHeader from "../updated/WebinarHeader";
import WebinarAboutSection from "../WebinarAboutSection";

export default function WebinarTemplate({
  headerSection,
  aboutSection,
  children,
  imageCenter = false,
  useHeroV2 = false,
}) {
  const {
    about,
    alt,
    image,
    learnLines,
    learnTitle,
    speakers,
    reverse,
  } = aboutSection;
  return (
    <>
      {useHeroV2 ? (
        <WebinarHero2 data={headerSection} />
      ) : (
        <WebinarHeader data={headerSection} imageCenter={imageCenter} />
      )}

      <WebinarAboutSection
        about={about}
        alt={alt}
        image={image}
        learnLines={learnLines}
        learnTitle={learnTitle}
        speakers={speakers}
        reverse={reverse}
      />
      {children}
      <IndustryLeadersCards />
    </>
  );
}
